import React, { useContext, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useShoppingCart } from 'use-shopping-cart';

import Context from '../../global/context';
import { formatPrice, MaxPriceItemsSold } from '../../utils/utils';
import styles from './styles';
import TipTopSelect from '../tiptopselect';

const ProductCard = ({ product }) => {
	const image = getImage(product.localFiles[0].childImageSharp);
	const { setCartOpen } = useContext(Context);
	const [loading, setLoading] = useState(false);
	const [selectedPriceId, setSelectedPriceId] = useState(
		product.prices[0].id
	);
	const { addItem, cartDetails, incrementItem, checkoutSingleItem } =
		useShoppingCart();

	const buttonDisabledStyles = {
		opacity: '0.5',
		cursor: 'not-allowed',
	};

	const handleChange = (e) => {
		setSelectedPriceId(e.target.value);
	};

	const handleBlur = (e) => {
		setSelectedPriceId(e.target.value);
	};

	const handleBuy = async () => {
		setLoading(true);
		const priceId = selectedPriceId;
		const { error } = await checkoutSingleItem(priceId);

		if (error) {
			console.warn('Error:', error);
			setLoading(false);
		}
	};

	const handleAddToCart = () => {
		if (!!cartDetails[selectedPriceId]) {
			if (
				cartDetails[selectedPriceId].quantity + 1 <=
				MaxPriceItemsSold
			) {
				incrementItem(selectedPriceId);
			}
		} else {
			const selectedPrice = product.prices.find(
				(price) => price.id === selectedPriceId
			);

			addItem(
				{
					name: product.name,
					price_id: selectedPriceId,
					price: selectedPrice.unit_amount,
					currency: selectedPrice.currency,
					size: selectedPrice.nickname,
				},
				{ count: 1, product_metadata: { type: 'fruit' } }
			);
		}

		setCartOpen(true);
	};

	return (
		<div css={styles.card}>
			<div css={styles.imageAndTitle}>
				{/* <Img
					backgroundColor="transparent"
					fluid={product.localFiles[0].childImageSharp.fluid}
					css={styles.cardImage}
				/> */}
				<GatsbyImage
					image={image}
					alt={product.name}
					css={styles.cardImage}
					backgroundColor="transparent"
				/>
				<div css={styles.cardTitle}>
					<p css={styles.productName}>{product.name}</p>

					<TipTopSelect
						fontSize="1rem"
						onChange={handleChange}
						onBlur={handleBlur}
						// value={selectedPriceId}
					>
						{product.prices.map((price) => (
							<option key={price.id} value={price.id}>
								{`${price.nickname} - ${formatPrice(
									price.unit_amount,
									price.currency
								)}`}
							</option>
						))}
					</TipTopSelect>
				</div>
			</div>

			<span css={styles.buttons}>
				<button
					style={loading ? buttonDisabledStyles : null}
					onClick={() => {
						if (!loading) {
							handleBuy();
						}
					}}
					disabled={loading}
				>
					<b>Buy</b>
				</button>
				<button onClick={handleAddToCart}>
					<b>Add To Cart</b>
				</button>
			</span>
		</div>
	);
};

export default ProductCard;
