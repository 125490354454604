import React from 'react'
import { css } from '@emotion/react';


import Icon from '../../assets/images/tiptop.svg';

const TipTopIcon = ({ size }) => {

	return (
		<Icon
			width={size}
			height={size}
			css={css`
				transition: ease-in-out fill 0.3s;
			`}
			alt="TipTop Icon, loooks like a happy squid"
		/>
	);
};

export default TipTopIcon;
