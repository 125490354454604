import { css } from '@emotion/react';

const styles = {
	prodcutsContainer: css`
		height: fit-content;
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		margin: 3rem auto;
        flex-wrap: wrap;
	`,
};

export default styles;
