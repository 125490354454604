import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import styles from './style';
import ProductCard from '../productcard';

const Products = () => {
	return (
		<StaticQuery
			query={graphql`
				query ProductPrices {
					prices: allStripePrice(
						filter: { active: { eq: true } }
						sort: { fields: [created] }
					) {
						edges {
							node {
								id
								active
								currency
								unit_amount
								nickname
								product {
									id
									name
									localFiles {
										childImageSharp {
											gatsbyImageData(
												width: 300
												placeholder: BLURRED
												formats: [AUTO, WEBP, AVIF]
											  )
										}
									}
								}
							}
						}
					}
				}
			`}
			render={({ prices }) => {
				const products = {};
				for (const { node: price } of prices.edges) {
					const product = price.product;
					if (!products[product.id]) {
						products[product.id] = product;
						products[product.id].prices = [];
					}
					products[product.id].prices.push(price);
				}

				return (
					<div css={styles.prodcutsContainer}>
						{Object.keys(products).map((pkey) => (
							<ProductCard
								key={products[pkey].id}
								product={products[pkey]}
							/>
						))}
					</div>
				);
			}}
		/>
	);
};

export default Products;
