import { css } from '@emotion/react';

const styles = {
	card: css`
		flex-basis: 300px;
		margin: 3rem 3rem;

		@media (max-width: 300px) {
			margin: 2rem 0rem;
		}
	`,
	imageAndTitle: css`
		height: fit-content;
		/* cursor: pointer;

			&:hover {
				text-decoration: underline;
			} */
	`,
	cardImage: css`
		border-radius: 5px;
	`,
	cardTitle: css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 0.8rem;
	`,
	productName: css`
		text-align: center;
		width: fit-content;
		margin-top: 0.7rem;
		margin-bottom: 0.5rem;
		position: relative;
	`,
	buttons: css`
		display: flex;
		justify-content: space-evenly;
	`,
};

export default styles;
