import React from 'react';

import styles from '../styles/index_styles';
import Layout from '../components/layout/index';
import TipTopIcon from '../components/tiptopicon/index';
import Products from '../components/products/index'
import Seo from '../components/seo'

export default function Home({ data }) {

	return (
		<Layout>
			<Seo />
			<main className="mainContent">
				<section css={styles.logoContainer}>
					<TipTopIcon size={230} />
					<h1 css={styles.logoTitle}>TipTop</h1>
					<h3 css={styles.logoDesc} >Cute Minimal Wear</h3>
				</section>
				<Products />
			</main>
		</Layout>
	);
}

// export const query = graphql`
// 	query MyQuery {
// 		file(relativePath: { eq: "images/tiptop.png" }) {
// 			childImageSharp {
// 				fluid(maxHeight: 600, fit: CONTAIN) {
// 					...GatsbyImageSharpFluid_tracedSVG
// 				}
// 			}
// 		}
// 	}
// `;
